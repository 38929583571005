<template>
  <div class="wrap">
    <svg class="bnb-icon" aria-hidden="true">
      <use xlink:href="#icon-a-zu206"></use>
    </svg>
    <div id="header">
      <div class="title">MEB TEAM</div>
      <div class="type-list">
        <div class="type-item">理事提案</div>
      </div>
      <div class="des">
        所有xMEB持有用户均可提交社区提案，获得超过5为理事支持的提案将成为治理提案，进行社区公投
      </div>
    </div>
    <div id="select-box">
      <div class="title">选项</div>
      <div class="select-item">支持AVAX跨链</div>
      <div class="select-item">不支持AVAX跨链</div>
    </div>
    <div id="info-box">
      <div class="title">提案信息</div>
      <div class="info-item">
        <div class="key">提案发起人</div>
        <div class="value">0x213123…123</div>
      </div>
      <div class="info-item">
        <div class="key">提案发起人</div>
        <div class="value">0x213123…123</div>
      </div>
      <div class="info-item">
        <div class="key">提案发起人</div>
        <div class="value">0x213123…123</div>
      </div>
      <div class="info-item">
        <div class="key">提案发起人</div>
        <div class="value">0x213123…123</div>
      </div>
    </div>
    <div id="vote-box">
      <div class="title">支持与反对理事</div>
      <div class="des">
        * 获得超过5为理事支持的社区提案，将成为治理提案，并进行社区公投
      </div>
      <VoteNumber
        :favorNum="favorNum"
        :opposeNum="opposeNum"
        :favorPercent="favorPercent"
      ></VoteNumber>
      <div class="button-group" v-show="false">
        <div class="confirm-button" @click="handleShowVote(1)">赞成</div>
        <div class="oppose-button" @click="handleShowVote(2)">反对</div>
      </div>
    </div>
    <div id="vote-info">
      <div class="title">投票信息</div>
    </div>
    <div id="vote-list">
      <div class="title">投票记录</div>
      <div class="list-item" v-for="item in 2" :key="item">
        <div class="info">
          <div class="address">0x32…523</div>
          <div class="des">支持AVAX跨链</div>
        </div>
        <div class="num">52312</div>
      </div>
      <div class="line"></div>
      <div class="check-more">
        <span>查看更多</span>
      </div>
    </div>
    <VotePop
      v-if="voteShow"
      @close="handleShowVote"
      :isFavor="isFavor"
    ></VotePop>
  </div>
</template>

<script>
import VoteNumber from '../components/VoteNumber'
import VotePop from './components/VotePop.vue'
export default {
  name: 'PropsalDetail',
  data () {
    return {
      voteShow: false,
      isFavor: 1, // 1赞成 2反对
      favorNum: 0,
      opposeNum: 0,
      favorPercent: 90
    }
  },
  methods: {
    handleShowVote (isFavor) {
      this.isFavor = isFavor
      this.voteShow = !this.voteShow
    }
  },
  mounted () {
  },
  components: { VotePop, VoteNumber }
}
</script>

<style scoped lang="scss">
.wrap {
  padding: 20px;
  margin-bottom: 50px;
  .title {
    padding-top: 10px;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
  }
  .bnb-icon {
    width: 110px;
    height: 30px;
    padding-left: 30px;
    padding-bottom: 15px;
  }
  #header {
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

    .type-list {
      padding-top: 15px;
      display: flex;
      align-items: center;
      font-size: 12px;
      .type-item {
        width: 66px;
        height: 25px;
        border: 1px solid #000000;
        line-height: 25px;
        text-align: center;
        border-radius: 5px;
        margin-right: 10px;
      }
    }
    .des {
      padding: 15px 0;
      font-size: 12px;
      color: #818086;
      line-height: 1.2rem;
    }
  }
  #select-box {
    margin-top: 15px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .title {
      font-size: 17px;
    }
    .select-item {
      margin-top: 20px;
      line-height: 50px;
      font-size: 15px;
      padding-left: 20px;
      height: 50px;
      border: 1px solid #000000;
      border-radius: 10px;
    }
  }
  #info-box {
    margin-top: 15px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .info-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      font-size: 12px;
      .key {
        font-weight: bold;
      }
    }
  }
  #vote-box {
    margin-top: 15px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    .title {
      font-size: 17px;
    }
    .des {
      padding: 15px 0;
      font-size: 12px;
      color: #818086;
    }

    .confirm-button {
      margin-top: 30px;
      height: 50px;
      font-size: 15px;
      font-weight: bold;
      background: #000;
      color: #fff;
      text-align: center;
      line-height: 50px;
      border-radius: 10px;
    }
    .oppose-button {
      height: 50px;
      margin-top: 20px;
      font-size: 15px;
      font-weight: bold;
      background: #fff;
      color: #000;
      text-align: center;
      line-height: 50px;
      border-radius: 10px;
      border: 1px solid #000;
    }
  }
  #vote-info,
  #vote-list {
    margin-top: 15px;
    background-color: #fff;
    padding: 20px 30px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    .title {
      font-size: 17px;
      padding-bottom: 20px;
    }
  }
  #vote-list {
    .list-item {
      border-top: 1px solid #cac4c5;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .address {
        padding-bottom: 5px;
      }
      .num {
        font-weight: bold;
      }
    }
    .line {
      height: 1px;
      background-color: #cac4c5;
    }
    .check-more {
      margin-top: 10px;
      text-align: center;
      color: #cac4c5;
      span {
        border-bottom: 1px solid #cac4c5;
      }
    }
  }
}
</style>
