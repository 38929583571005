<template>
  <VanPopup
    v-model="isShow"
    style="width: 93%; max-width: 400px"
    round
    @click-overlay="closePop"
  >
    <div class="wrap">
      <div class="title-wrap">
        <div class="title">确认选择</div>
        <div class="close-icon" @click="closePop">✕</div>
      </div>
      <div class="content">
        <div class="select">选择给</div>
        <div class="type" v-if="isFavor === 1">赞成</div>
        <div class="type" v-if="isFavor === 2">反对</div>
        <div class="des">确认要为上述投票吗？此操作无法撤销</div>
        <div class="cofirm-button" @click="handleToConfirm">确定投票</div>
        <div class="cancle-button" @click="handleToConfirm">取消</div>
      </div>
    </div>
  </VanPopup>
</template>

<script>
import config from '@/config/app.config'
const contractsInfo = config.contractsInfo
export default {
  name: 'VotePop',
  props: {
    isFavor: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      tokenMeb: contractsInfo.tokenGEPContract,
      mebBalance: 0,
      mebValue: 0,
      isShow: true
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    },
    async getMebBalance () {
      const mebResp = await this.$web3.balanceOf(this.tokenMeb)
      if (mebResp.success) {
        this.mebBalance = this.$gbUtils.formatTwoBalance(mebResp.result) || 0
      }
    },
    handleToMax () {
      this.mebValue = this.mebBalance
    },
    handleToConfirm () {
      this.closePop()
    }
  },
  mounted () {
    this.getMebBalance()
    console.log(this.isFavor)
  }
}
</script>

<style scoped lang="scss">
.wrap {
  font-size: 12px;
  .title {
    font-weight: bold;
  }
  .line {
    height: 1px;
    background-color: #e3e3e3;
  }
  .title-wrap {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid #e3e3e3;
    .close-icon {
      background-color: #000 !important;
      font-size: 14px;
    }
  }
  .content {
    padding: 15px 25px;
    .select {
      font-weight: bold;
      font-size: 12px;
    }
    .type {
      font-size: 20px;
      font-weight: bold;
      padding: 10px 0;
    }
    .des {
      padding: 15px 0;
      color: #818086;
      line-height: 1.2rem;
    }
  }
  .cofirm-button {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
    text-align: center;
    border-radius: 10px;
  }
  .cancle-button {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    background: #c1c1c1;
    color: #fff;
    text-align: center;
    border-radius: 10px;
  }
}
</style>
