<template>
  <div class="vote-num-group">
    <div class="iconfont icon-duigouzhong icon"></div>
    <div
      class="black"
      :style="{
        width: `${favorPercent}%`,
        backgroundColor: color,
        borderColor: color,
      }"
    >
      {{ favorNum }}
    </div>
    <div
      class="white"
      :style="{ width: `${100 - favorPercent}%`, borderColor: color }"
    >
      {{ opposeNum }}
    </div>
    <div class="icon x" :style="{ borderColor: color }">
      <i class="iconfont icon-chacha"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VoteNumber',
  props: ['favorNum', 'opposeNum', 'favorPercent', 'color'],
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.vote-num-group {
  display: flex;
  align-items: center;
  position: relative;
  .icon {
    position: absolute;
    width: 31px;
    height: 28px;
    border-radius: 15px;
    font-size: 40px;
    text-align: center;
    line-height: 30px;
  }
  .icon:first-child {
    z-index: 9;
    background-color: #fff;
    margin-left: -7px;
  }
  .icon:last-child {
    width: 21px;
    height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    z-index: 9;
    border-radius: 15px;
    right: 0px;
    font-size: 20px;
    border: 2px solid #000;
    background-color: #fff;
  }
  .white {
    min-width: 50px;
    text-align: center;
    height: 20px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin-right: 10px;
  }
  .black {
    min-width: 50px;
    text-align: center;
    margin-left: 5px;
    height: 20px;
    color: #fff;
    background-color: #000;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
}
</style>
